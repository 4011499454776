<template>
  <qzf-tabs v-model:value="value" @tab-click="handleClick">
    <qzf-tabs-item label="税局检查" name="1" :cache="false">
      <taxCheckSj ref="batchSendSb" :activeName = "activeName"></taxCheckSj>
    </qzf-tabs-item>
    <!-- <qzf-tabs-item label="社保检查" name="2" :cache="false">
      <taxCheckSb></taxCheckSb>
    </qzf-tabs-item> -->
  </qzf-tabs>
</template>

<script>
import taxCheckSj from "./components/taxCheckSj"
import taxCheckSb from "./components/taxCheckSb"
export default {
  name:'',
  components:{
    taxCheckSj,
    taxCheckSb
  },
  data(){
    return {
      activeName:'first',
      value:'1',
      listQuery: {
        page: 1,
        limit: 20,
        setUpStatusCj:[1],
        from:'init'
      },
    }
  },
  methods:{
    
  }
}
</script>

<style lang="scss" scoped>

</style>