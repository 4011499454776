<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="我的申报" name="我的申报"></el-tab-pane>
      <el-tab-pane label="学生申报" name="学生申报" v-if="this.userRoleName != '学生'"></el-tab-pane>
   <!-- todo -->
      <div class="top_btns">
        <div class="left_box">
          <qzf-search-period v-model:period="listQuery.period" @success="getList"></qzf-search-period>

          <!-- <el-date-picker @change="getList" :clearable="false" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker> -->
          <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;margin-left:10px" class="filter-item" size="small" @keyup.enter="getList" />
          <el-button class="filter-item1" type="primary" size="small" @click="getList"   >
            <el-icon><Search /></el-icon><span  > 搜索</span>
          </el-button>
          <search @success="getList">
            <el-form style="margin:11px 0px 0px 6px;width:500px" class="styleForm">
              <el-form-item label="选择人员 ：" :label-width="formLabelWidth">
                <selectuser code="bookkeeping" v-model:userId="listQuery.userId"></selectuser>
              </el-form-item>
              <el-form-item label="纳税人类型 ：" :label-width="formLabelWidth">          
                <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
              </el-form-item>
              <el-form-item label="检查状态：" :label-width="formLabelWidth">
                <el-checkbox-group size="small" v-model="listQuery.taskStatus">
                  <el-checkbox-button v-for="item in options" :key="item.value" :label="item.value">{{item.label}}</el-checkbox-button>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </search>
        </div>
        <div class="right_box">
          <el-button size="small" type="primary" @click="declaration()"   >
            <el-icon><Checked /></el-icon><span  > 批量检查</span>
          </el-button>
        </div>
      </div>
      <div class="">
        <el-table stripe :data="list" :height="contentStyleObj" border @selection-change="handleSelectionChange" @filter-change="tableFilterChange">
          <template #empty>
            <el-empty :image-size="150" description="没有数据"></el-empty>
          </template>
          <el-table-column align="center" prop="id" type="selection" min-width="55" />
          <el-table-column align="center" type="index" label="编号" width="60" />
          <el-table-column align="left" prop="name" label="公司名称" min-width="240" class="gsmc">
            <template #default="scope">
              <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
              <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
              <span style="cursor: pointer;color:#39b0d2" @click="$goEtax(scope.row.comId)">{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="districtName" label="检查状态" min-width="180">
            <template #default="scope">
              <div class="div_p">
                <span v-if="scope.row.image && scope.row.taskStatus == '3'" class="demo-image__preview" style="position: absolute;left: 50px;">
                  <i class="iconfont icon-picture" style="color:#409eff;font-size:16px;" @click="open(scope.row.image)"></i>
                </span>
                <div class="item_icon">
                  <p v-if="scope.row.taskStatus == '0'">{{$setUpStatusJc(scope.row.taskStatus)}}
                  </p>
                  <p v-if="scope.row.taskStatus == '1'"><i class="iconfont icon-info"></i>{{$setUpStatusJc(scope.row.taskStatus)}}
                  </p>
                  <p v-if="scope.row.taskStatus == '2'"><i class="iconfont icon-info"></i>{{$setUpStatusJc(scope.row.taskStatus)}}</p>
                  <p v-if="scope.row.taskStatus == '3'"><i class="iconfont icon-duihao"></i>
                  <el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog" placement="top-start">
                    <el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusJc(scope.row.taskStatus)}}</el-button>
                  </el-tooltip>
                  <span v-else><el-button size="small" class="sbcg" style="border: 0;">{{$setUpStatusJc(scope.row.taskStatus)}}</el-button></span>
                  </p>
                  <p v-if="scope.row.taskStatus == '4'"><i class="iconfont icon-cuowu"></i>
                  <el-tooltip v-if="scope.row.errLog" class="item" effect="dark" :content="scope.row.errLog" placement="top-start">
                    <el-button size="small" class="sbyc" style="border: 0;">{{$setUpStatusJc(scope.row.taskStatus)}}</el-button>
                  </el-tooltip>
                  <span v-else><el-button size="small" class="sbyc" style="border: 0;">{{$setUpStatusJc(scope.row.taskStatus)}}</el-button></span>
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="manager" label="操作" min-width="180">
            <template #default="scope">
              <div class="div_p">
                <el-button @click="handleUpdate(scope.row)" size="small" type="text">检查</el-button>
                <el-button @click="declareDetail(scope.row)" size="small" type="text">检查记录</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </el-tabs>
  </div>
  <sbImgUrl ref="sbImgUrl"></sbImgUrl>
  <declareDetails ref="declareDetails"></declareDetails>
</template>

<script>
import { companyInitTaxList } from "@/api/company"
import selecttaxtype from "@/components/Screening/selecttaxtype";
// import selectuser from "@/components/Screening/selectuser";
import declareDetails from "./declareDetails"
import { currentAccountPeriod } from '@/utils'
import { sendTask } from "@/api/collection"
import sbImgUrl from "./sbImgUrl"
export default {
  name: "batchSendSb",
  components:{
    selecttaxtype,
    // selectuser
    declareDetails,
    sbImgUrl
  },
  data(){
    return {
      activeName:'我的申报',
      formLabelWidth:'100px',
      listQuery: {
        page: 1,
        limit: 20,
        // status:'2',
        taskName:"tax-jc-shebao",
        name: '',
        period: currentAccountPeriod(),
        taskStatus: [],
        accountingType: 2,
        taxNames:[],
        status: 2,
        userIds:[this.$store.getters['user/user'].userId]

      },
      total:0,
      list:[],
      gsMainIds: undefined,
      pageStatus: true,

      options: [
        {
          value: 0,
          label: '未检查',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '检查中',
        },
        {
          value: 3,
          label: '检查完成',
        },
        {
          value: 4,
          label: '检查异常',
        },
      ],
      categories: [
        {text: '利润表', value: 'kj_lrb'}, 
        {text: '资产负债表', value: 'kj_zcfz'}, 
        {text: '增值税及附加税一般纳税人申报表', value: 'gs_vat'}, 
        {text: '小规模增值税纳税及附加税申报表', value: 'gs_small_vat'},
        {text: '财产和行为税纳税申报表', value: 'gs_deed'},
        {text: '企业所得税申报表', value: 'gs_tax_quarter'},
        {text: '综合所得个人所得税预扣预缴申报表', value: 'gs_gs'},
        {text: '附加税', value: 'gs_fjs'},
        {text: '文化事业建设费申报表', value: 'gs_whsyjsfsbbygz'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item'},
        {text: '残疾人就业保障金申报表', value: 'gs_cbj'},
        {text: '工会经费', value: 'gs_ghjf'},
        {text: '房产税土地税', value: 'gs_fcs'},
        {text: '非税收入', value: 'gs_fssr'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_cb'},
        {text: '印花税纳税申报表', value: 'gs_stamp_duty_item_jb'},
        {text: '水利建设专项收入', value: 'gs_sl'},
        {text: '城市生活垃圾处置费', value: 'gs_ljcl'},
        {text: '工会经费', value: 'gs_qtsr'},
        {text: '个人生产经营所得(查账征收)', value: 'gs_gs_one'},
        {text: '现金流量表', value: 'kj_xjllb'},
        {text: '单位社会保险费日常申报(全责)', value: 'gs_sb'},
      ],
      userRoleName:this.$store.getters['user/user'].userRoleName

    }
  },
  activated(){
    this.pageStatus = true
  },
  deactivated(){
    this.pageStatus = false
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(300)
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.on("refreshTask", (val) => {
        if(!this.pageStatus){
          return
        }
        if(this.gsMainIds && this.gsMainIds.length != 0){
          return
        }
        if(this.list.length == 0){
          return
        }
        //刷新业务
        let status = false
        this.list.forEach(res => {
          if(res.taskStatus == 1 || res.taskStatus == 2) {
            status = true
          }
        })
        if(status == true) {
          this.getList()
        }
      });
    },
    handleClick(tab) {
      this.listQuery.userIds = []
      if(tab.props.name == "我的申报") {
        this.listQuery.userIds = [this.$store.getters['user/user'].userId]
        this.getList()
      }if(tab.props.name == "学生申报") {
        this.getList()
      }
    },
    getList(){
      companyInitTaxList(this.listQuery).then(res=>{
        this.list = res.data.data.list
        this.total = res.data.data.total
      })
    },
    open(row) {
      this.$refs.sbImgUrl.dialog('https://file.listensoft.net/' + row)
    },
    tableFilterChange(filters) {
      const values=Object.values(filters)
      const value=values[0]
      this.listQuery.taxNames=value
      this.getList()
    },
    handleSelectionChange(row) {
      this.gsMainIds = []
      row.map(info=>{
        this.gsMainIds.push(info.comId)
      })
    },
    // 检查详情
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.listQuery.period,e.taskName)
    },
    //批量检查
    declaration() {
      if(!this.gsMainIds){
        this.$qzfMessage("请选择公司", 1)
        return
      }
      this.$confirm('确定要批量检查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax-jc-shebao',
          comIds: this.gsMainIds,
          period: this.listQuery.period
        }
        sendTask(param).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    //检查
    handleUpdate(e) {
      this.$confirm('确定要检查吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          taskName: 'tax-jc-shebao',
          comIds: [e.comId],
          period: this.listQuery.period
        }
        sendTask(param).then(res => {
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)

          }
        })
      })
    },
    getUserList(){
      this.$refs.selectuser.init(this.listQuery.deptId)
    },
    getUser(e){
      this.listQuery.userIds = []
      if(this.listQuery.userIds.length == 0){
        e.map(v => {
          this.listQuery.userIds.push(v.id)
        })
      } 
    }
  }
}
</script>

<style lang="less" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
.filter-item1 {
  margin-right: 10px;
}
 
.el-table__column-filter-trigger .el-icon-arrow-down {
  color: white !important;
  width: 10px;
}
.none-border .div_p {
  line-height: 30px;
  border-bottom: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:last-child{
    border-bottom: none;
  }
}
.xgmStyle,.ybStyle {
  margin-left: 10px;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    line-height: 28px;
    font-size: 16px;
  }
  .iconfont icon-cuowu{
    color: #f56c6c;
  }
  .iconfont icon-duihao{
    color: #67c23a;
  }
  .el-icon-circle-check{
    color: #67c23a;
  }
  .iconfont icon-info{
    color: #409eff
  }
  .iconfont icon-cuowu{
    color: #e6a23c;
  }
  .iconfont icon-cuowu-outline{
    color: #e6a23c;
  }

  p{
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    color: #333;
  }
}
.sbcg {
  padding: 0 !important;
}
.sbyc {
  color: red !important;
  padding: 0 !important;
}
</style>
<style lang="scss">
.el-table__column-filter-trigger i {
  color: black !important;
  width: 10px;
}
.none-border .cell {
  padding: 0;
}
.el-table th.el-table__cell>.cell.highlight {
    color: black !important;
}
</style>