<template>
	<el-checkbox-group size="small" v-model="taskStatus">
		<el-checkbox-button v-for="item in options" :key="item.value" :label="item.value" @change="changeValue">{{item.label}}</el-checkbox-button>
	</el-checkbox-group>
</template>

<script>
export default {
  name: 'selectTaskStatus',
  props:{
		taskStatus:0
	}, 
  data() {
    return {
      options: [
        {
          value: 0,
          label: '未检查',
        },
        {
          value: 1,
          label: '排队中',
        },
        {
          value: 2,
          label: '检查中',
        },
        {
          value: 3,
          label: '完成',
        },
        {
          value: 4,
          label: '失败',
        }
      ],
    };
  },

  mounted() {
    
  },

  methods: {
    changeValue() {
      this.$emit('update:taskStatus', this.taskStatus)
      this.$emit("success",this.taskStatus)
    },   
  },
};
</script>

<style lang="scss" scoped>

</style>